header {
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .menu_block {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    .button_block {
      width: 281px;
      height: 58px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;

      .open_account {

        width: 133px;
        height: 40px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #919CA2;
        background: transparent;
        gap: 2px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: center;
        color:#D9DEE2;
        cursor: pointer;

      }
    }
  }
  .header_block {
    position: relative;
    width: 1180px;
    height: 660px;
    display: flex;
    align-items: center;
    margin-bottom: 140px;

    &::after {
      position: absolute;
      background: url('../../assets/NoteBook.png') center/cover no-repeat;
      width: 376px;
      height: 736px;
      top: 220px;
      left: 1170px;
      content: '';
      pointer-events: none;
    }



  .title_block {
      width: 580px;
      height: 124px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

    &::after {
      position: absolute;
      background: url('../../assets/Macbook 1.png') center/cover no-repeat;
      width: 1300px;
      height: 895px;
      top: -30px;
      left: 240px;
      content: '';
      pointer-events: none;

    }


      h1 {
        width: 580px;
        font-family: 'Prompt';
        font-weight: 500;
        font-size: 55px;
        line-height: 62px;
        color: #FFFFFF;
      }
      div {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 20px;

        span {
          width: 536px;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #919CA2;
        }
    }
      .start_now_button {
        width: 180px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 0px 16px 0px;
        background: #8A24F3;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
        border: none;

        &::before {
          position: absolute;
          background: url('../../assets/keyboard.png') center/cover no-repeat;
          width: 630px;
          height: 713px;
          top: 290px;
          left: -370px;
          content: '';
          pointer-events: none;
        }
      }
  }
  }

  .simple_and_fascinating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-bottom: 120px;

    h2 {
      width: 580px;
      height: 52px;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      color: #FFFFFF;
    }

    .simple_block {
      display: flex;
      align-items: baseline;
      gap: 60px;


      .icon_block {
        width: 353px;
        height: 256px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

      .about {
        width: 353.33px;
        height: 28px;
        font-family:  'Prompt';
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
      }
      .about2 {
        width: 353.33px;
        height: 48px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        text-align: center;
        color: #919CA2;
      }
      }
    }
  }
}




@media screen and (max-width: 767px) {
  header {
    padding-top: 16px;


    .menu_block {
      width: 328px;
      gap: 12px;
      margin-bottom: 40px;

      .button_block {
        width: 281px;
        height: 58px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .open_account {

          width: 133px;
          height: 40px;
          border-radius: 6px;
          border: 1px solid #919CA2;
          background: transparent;
          gap: 2px;
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
          text-align: center;
          color: #D9DEE2;
          padding: 0px 4px 0px 4px;
          cursor: pointer;

        }
      }
    }

    .header_block {
      position: relative;
      width: 328px;
      height: 550px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      h1 {
        width: 328px;
        font-family: 'Prompt';
        font-size: 32px;
        line-height: 40px;
        color: #FFFFFF;
      }

      div {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 12px;

        span {
          width: 292px;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #919CA2;
        }
      }


      .start_now_button {
        width: 180px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 0px 16px 0px;
        background: #8A24F3;
        font-family: 'Roboto';
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        position: relative;


        &::before {
          position: absolute;
          background: url('../../assets/NoteBook2.png') center/cover no-repeat;
          width: 360px;
          height: 584px;
          top: -120px;
          left: -40px;
          content: '';
          pointer-events: none;
        }
        &::after {
          position: absolute;
          background: url('../../assets/iphone.png') center/cover no-repeat;
          width: 269px;
          height: 821px;
          top: -390px;
          left: 90px;
          content: '';
          pointer-events: none;
        }

      }
    }
  }

  .simple_and_fascinating {

    width: 360px;
    height: auto;
    gap: 32px!important;
    margin-bottom: 40px!important;

    .simple_title {
      width: 360px;
      height: 40px;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      color: #FFFFFF;
    }

    .simple_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px!important;


      .icon_block {
        width: 100%;
        height: 175px!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px!important;

        .about {
          width: 100%;
          height: 24px;
          font-family:  'Prompt'!important;
          font-weight: 500!important;
          font-size: 16px!important;
          line-height: 24px!important;
          text-align: center!important;
          color: #FFFFFF;
        }
        .about2 {
          width: 100%;
          height: 40px!important;
          font-family: 'Roboto'!important;
          font-weight: 400!important;
          font-size: 14px!important;
          line-height: 20px!important;
          letter-spacing: 0.4px!important;
          text-align: center!important;
          color: #919CA2;
        }
      }
    }
  }


}