main {
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {

    margin-top: auto;
  }

  .form_back {

    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 554px;
    border-radius: 32px;
    background: linear-gradient(83.11deg, rgba(138, 139, 142, 0.1) 3.83%, rgba(239, 239, 239, 0.1) 59.24%, rgba(84, 85, 88, 0.1) 77.71%);
    margin-bottom: 120px;
    position: relative;


    &::before {
      position: absolute;
      background: url('../../assets/card.png') center/cover no-repeat;
      width: 316px;
      height: 250px;
      top: 30px;
      left: 1180px;
      content: '';
      pointer-events: none;
    }

    form {
      padding-top: 40px;
      padding-left: 40px;
      width: auto;
      height: 496px;
      display: flex;
      flex-direction: column;
      gap: 24px;




      &::before {
        position: absolute;
        background: url('../../assets/envelope.png') center/cover no-repeat;
        width: 340px;
        height: 550px;
        bottom: 230px;
        left: 1210px;
        content: '';
        pointer-events: none;
        z-index: -1;
      }

      h2 {
        width: 382px;
        height: 52px;
        font-family: "Prompt";
        font-weight: 500;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: 0.4px;
        color: #FFFFFF
      }

      p {
        padding: 0  ;
      }

      .inputs_block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .name_group, .phone_group {
          width: 646px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          gap: 12px;

          input {
            width: 311px;
            height: 56px;
            border-radius: 8px;
            border: 1px solid #D9DEE2;
            padding: 16px;
            font-family: 'Roboto';
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.5px;
            outline: none;
            font-size: 16px;
            transition: all .1s ease;
            color: #171E27;
          }

          .label_group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            label {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.5px;
              color: #5E6B74;
              padding-bottom: 16px;

            }

            .error_container {
              height: 6px;

              .error {
                padding: 5px;
                color: red;
                font-family: "Roboto";
                font-size: 0.8rem !important;
              }
            }
          }

        }
      }

      .submit_button {
        width: 646px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 0px 16px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7.59px;
        background: #8A24F3;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }

      p {
        width: 646px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #5E6B74;
        border: none;

      }
    }
  }

  span {

    font-family: "Roboto";
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.4px;
    text-align: center;
    color: black;
  }

  .error.visible {
    transform: scale(100%);
  }

  .button_disabled {

    width: 646px;
    height: 56px;
    border-radius: 8px;
    padding: 16px 0px 16px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.59px;
    background: #8A24F3;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    border: none;
    cursor: wait !important;
  }

  .popup_container {

    align-items: center;
    background: #000000B2;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }
}

@media screen and (max-width: 767px) {


  main {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;


    .form_back {
      width: 360px;
      height: 1000px;
      padding: 0;


      form {

        width: 360px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        gap: 0px;
        padding: 0;


        .title_block {

          padding-top: 27.47px;
          width: 282.01px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;

          h2 {

            font-weight: 600;
            font-size: 39.24px;
            line-height: 53.61px;
            letter-spacing: 0.27px;
          }

          span {

            position: absolute;
            transform: translate(120%, 100%);
            width: 137.02px;
            height: 57.87px;
            border-radius: 12px;
            padding: 0.96px 4.28px 4.28px 4.28px;
            font-weight: 700;
            font-size: 34.07px;
            line-height: 46.55px;
            letter-spacing: 0.17px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4.28px;
            color: #171E27;
          }
        }

        .inputs_block {

          width: 311px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          margin-bottom: 40px;

          .name_group, .phone_group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            input {
              width: 311px;
              height: 56px;
              border-radius: 12px;
              border: 1px solid #D9DEE2;
              padding: 16px;
              font-family: 'Roboto';
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.5px;
              outline: none;
              font-size: 16px;
              transition: all .1s ease;
            }

            .label_group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              label {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #5E6B74;
                padding-bottom: 16px;

              }

              .error_container {
                height: 6px;

                .error {
                  padding: 5px;
                  color: red;
                  font-family: 'Roboto';
                  font-size: 0.8rem !important;
                }
              }
            }

          }
        }

        .submit_button {
          width: 308px;
          height: 56px;
          border-radius: 12px;
          padding: 16px 0px 16px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          background: #0779F8;
          margin-bottom: 12px;

        }

        p {
          transform: translateY(-210%);
          width: 311px;
          height: 120px;
          font-weight: 400;

        }

        img {

          width: 362.37px;
          height: 280px;
        }


      }
    }

    span {

      font-family: 'Roboto';

      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 0.4px;
      text-align: center;
      color: black;
    }

    .button_disabled {

      width: 308px;
      height: 56px;
      border-radius: 12px;
      padding: 16px 0px 16px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      background: #0779F8;
      border: none;
      cursor: wait !important;
      margin-bottom: 12px;
    }
  }
}


@media screen and (max-width: 767px) {
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      margin-top: auto;
    }

    .form_back {
      display: flex;
      justify-content: space-between;
      height: 560px;
      width: 100%;
      border-radius: 32px;
      background: linear-gradient(83.11deg, rgba(138, 139, 142, 0.1) 3.83%, rgba(239, 239, 239, 0.1) 59.24%, rgba(84, 85, 88, 0.1) 77.71%);
      margin-bottom: 120px;
      position: relative;

      &::before {
        position: absolute;
        background: url('../../assets/card.png') center/cover no-repeat;
        width: 316px;
        height: 250px;
        top: 30px;
        left: 1180px;
        content: '';
        pointer-events: none;
      }

      form {
        padding-left: 32px;
        padding-top: 32px;
        width: 646px;
        height: 360px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &::before {
          position: absolute;
          background: url('../../assets/envelope.png') center/cover no-repeat;
          width: 340px;
          height: 550px;
          bottom: 230px;
          left: 1210px;
          content: '';
          pointer-events: none;
          z-index: -1;
        }

        h2 {
          width: 382px;
          height: 52px;
          font-family: "Prompt";
          font-weight: 500;
          font-size: 40px;
          line-height: 52px;
          letter-spacing: 0.4px;
          color: #FFFFFF
        }


        .inputs_block {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .name_group, .phone_group {
            width: 646px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 12px;

            input {
              width: 311px;
              height: 56px;
              border-radius: 8px;
              border: 1px solid #D9DEE2;
              padding: 16px;
              font-family: 'Roboto';
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.5px;
              outline: none;
              font-size: 16px;
              transition: all .1s ease;
              color: #171E27;
            }

            .label_group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0;

              label {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #5E6B74;
                padding-bottom: 16px;

              }

              .error_container {
                height: 6px;

                .error {
                  padding: 5px;
                  color: red;
                  font-family: "Roboto";
                  font-size: 0.8rem !important;
                }
              }
            }

          }
        }

        .submit_button {
          width: 646px;
          height: 56px;
          border-radius: 8px;
          padding: 16px 0px 16px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7.59px;
          background: #8A24F3;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #FFFFFF;
          border: none;
          cursor: pointer;
        }

        p {
          width: 646px;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #5E6B74;

        }
      }
    }

    span {

      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 0.4px;
      text-align: center;
      color: black;
    }

    .error.visible {
      transform: scale(100%);
    }

    .button_disabled {

      width: 646px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 0px 16px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7.59px;
      background: #8A24F3;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      border: none;
      cursor: wait !important;
    }

    .popup_container {

      align-items: center;
      background: #000000B2;
      display: flex;
      height: 100vh;
      justify-content: center;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {


    main {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;


      .form_back {
        width: 340px;
        flex-direction: column;
        height: 935px;
        gap: 0px;
        margin-bottom: 40px;

        form {

          padding-left: 16px;
          width: 328px;
          height: 388px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 0px;
          border-radius: 16px;


          h2 {
            width: 296px;
            height: 40px;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            letter-spacing: 0.27px;
            margin-bottom: 12px;
          }


          .inputs_block {

            width: 311px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0px;
            margin-bottom: 12px;


            .name_group, .phone_group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0px;

              input {
                width: 311px;
                height: 56px;
                border-radius: 12px;
                border: 1px solid #D9DEE2;
                padding: 16px;
                font-family: 'Roboto';
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.5px;
                outline: none;
                font-size: 16px;
                transition: all .1s ease;
              }

              .label_group {

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                label {
                  margin-left: 10px;
                  transform: translateY(15%);
                  font-family: 'Roboto';
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.5px;
                  color: #5E6B74;
                  padding-bottom: 16px;

                }

                .error_container {
                  height: 6px;

                  .error {
                    padding: 5px;
                    color: red;
                    font-family: 'Roboto';
                    font-size: 0.8rem !important;
                  }
                }
              }

            }
          }

          .submit_button {
            width: 308px;
            height: 56px;
            border-radius: 12px;
            padding: 16px 0px 16px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background: #8A24F3 !important;
            margin-bottom: 12px;

          }

          p {
            transform: translateY(5%);
            width: 296px;
            height: 120px;
            font-weight: 400;
            line-height: 20px;
            color: #5E6B74;
            border: none;
          }

          img {

            width: 362.37px;
            height: 280px;
          }


        }
      }

      span {

        font-family: 'Roboto';

        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: 0.4px;
        text-align: center;
        color: black;
      }

      .button_disabled {

        width: 308px;
        height: 56px;
        border-radius: 12px;
        padding: 16px 0px 16px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: #8A24F3;
        border: none;
        cursor: wait !important;

      }
    }
  }
}
