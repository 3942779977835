footer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    width: 636px;
    height: 52px;
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 60px;
  }

  .icons_block {

    margin-bottom: 120px;
    width: 100%;
    height: 220px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;

    .icon {
      width: 151.43px;
      height: 60px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #434D56;
    }
  }

  .footer_block {
    width: 100%;
    height: 225px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .first {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px!important;
      padding-bottom: 20px;
      border-bottom: 1px solid #5E6B74;

      p {
        width: 323px;
        height: 32px;
        font-family: 'Roboto';
        font-size: 10px;
        line-height: 16px;
        color: #5E6B74;
        border: none;
      }

      .first_div {
        display: flex;
        align-items: center;
        gap: 54px;
        height: 48px;

        p {
          height: auto;
        }
      }
      .first_div_2 {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 48px;

        p {
          font-family: "Roboto";
          height: auto;
        }
      }

      ul {
        display: flex;
        align-items: center!important;
        gap: 20px;





          li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            transition: all .2s ease;
            cursor: pointer;

            &:hover {
              transform: scale(1.1);
            }
            a {

              img {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }


    .second {
      width: 100%;

      p {
        width: 100%;
        height: 80px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #5E6B74;
        border: none;
      }
    }
  }
}


@media screen and (max-width: 767px) {
  footer {
    width: 100%;


    h3 {
      width: 360px;
      height: 80px;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 40px;

    }

    .icons_block {
      display: flex;
      align-items: center;
      width: 328px;
      height: 60px;
      gap: 16px;
      margin-bottom: 60px;
    }
  }
  .footer_block {
    width: 360px;
    justify-content: center !important;
    align-items: center !important;
    gap: 0!important;
    margin-bottom: 0;

    .first {
      width: 328px !important;
      border: none !important;
      height: auto!important;
      padding: 0 !important;
      align-items: baseline;



      p {
        border: none;
        width: 200px !important;
        height: auto!important;
        font-size: 10px !important;
        line-height: 16px !important;
      }
    }
  }



  .ul_div {
    width: 328px;
    align-self: flex-start;
    margin-left: 35px;
    margin-bottom: 30px;

    ul {


      display: flex;
      align-items: center;
      gap: 20px;

      li {
        width: 48px;

      }
      }
    }
  }

    p {
      border-top: 1px solid #5E6B74;
      padding-top: 20px;
      margin-bottom: 20px;
      width: 328px;
      font-weight: 400;
      font-family: "Roboto";
      font-size: 10px;
      line-height: 16px;
      color: #5E6B74;

    }

